import '../src/App.css';
import NavBar from './Components/Navigation';
import Footer from './Components/Footer';
import * as React from 'react';
import { BalContext, IsLoggedInContext } from './BalContext';
import { Routes, Route, useLocation } from 'react-router-dom';
import Admin from './Components/Admin';
import UpdateAttendees from './Components/Admin/UpdateAttendees';
import Form from './Components/Form';
import Attendees from './Components/Attendees';
import Page from './Components/Page';
import SignIn from './Components/Account';
import { useUser } from './Hooks';
import { CircularProgress } from '@mui/material';
import UpdateAttendeeDetails from './Components/Admin/UpdateAttendees/Details';
import { toast, Toaster } from 'react-hot-toast';


function App() {
  const [localBalSettings, setLocalBalSettings] = React.useState();
  const [navigation, setNavigation] = React.useState();
  const [value, setValue] = React.useState(false);
  const location = useLocation();
  const { user, authenticated } = useUser(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setLoggedIn(user && authenticated);
  }, [user, authenticated]);

  const loadNavigation = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/GetNavigation`)
    .then((response) => {
      if (!response.ok) {
        toast.error("Något gick fel när sidnavigering skulle hämtas");
      }
      return response.json();
    })
    .then((data) => {
      setNavigation(data.pageIds);
    });
  };

  const loadBalSettings = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/GetBalSafe`)
    .then((response) => {
      if (!response.ok) {
        toast.error("Något gick fel när balinställningar skulle hämtas");
      }
      return response.text();
    })
    .then((data) => {
      const replacedText = data.replace(/T00:00:00/g, '');
      const parsedObj = JSON.parse(replacedText);
      document.title = parsedObj.balName
      setLocalBalSettings(JSON.parse(replacedText));
    });
  };
  
  React.useEffect(() => {
    loadBalSettings();
    loadNavigation();
  }, []);

  /* eslint-disable */
  React.useEffect(() => {
    if (location?.pathname === '/' && navigation) {
      setValue(navigation["Hem"]);
    }
    else if (navigation && location?.pathname) {
      const pathToSet = navigation[location.pathname.replace('/', '')];
      if (pathToSet) {
        setValue(pathToSet);
      }
      else {
        setValue(location.pathname.replace('/', ''));
      }
    }
  }, [navigation]);
  /* eslint-enable */

  if (!navigation || !localBalSettings) {
    return (
      <div className='App'>
        <CircularProgress />
        <Footer />
      </div>
    );
  }

  return (
    <BalContext.Provider value={ localBalSettings }>
      <IsLoggedInContext.Provider value={{ loggedIn, setLoggedIn }}>
        <div className='App'>
          <Toaster />
          <img src={ localBalSettings.mainImage.url } width='100%' alt={ localBalSettings.mainImage.alt }/>
          <NavBar value={ value } navigation={ navigation } handleChange={ handleChange } />
          <Routes>
            <Route exact path='/signin' element={ <SignIn /> } />
            <Route exact path="/" element={ <Page pageId={ value } /> } />
            { navigation &&
              Object.keys(navigation).map((keyName, i) => (
                <Route exact path={`/${keyName}`} element={ <Page pageId={ value } /> } key={ i } />
              )) 
            }
            <Route exact path="/Admin" element={<Admin reloadNavigation={ loadNavigation }/>} />
            <Route exact path="/Anmalan" element={<Form />} />
            <Route exact path="/Anmalda" element={<Attendees />} />
            <Route exact path="/UpdateAttendees" element={<UpdateAttendees />} />
            <Route exact path="/UpdateAttendees/:id" element={<UpdateAttendeeDetails />} />
          </Routes>
          <Footer />
        </div>
        <p className="watermark">Hemsida av MLC</p>
        </IsLoggedInContext.Provider>
    </BalContext.Provider>
  );
}


export default App;
